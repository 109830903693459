exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-apple-music-js": () => import("./../../../src/pages/apple-music.js" /* webpackChunkName: "component---src-pages-apple-music-js" */),
  "component---src-pages-design-index-js": () => import("./../../../src/pages/design/index.js" /* webpackChunkName: "component---src-pages-design-index-js" */),
  "component---src-pages-design-promptplayground-index-js": () => import("./../../../src/pages/design/promptplayground/index.js" /* webpackChunkName: "component---src-pages-design-promptplayground-index-js" */),
  "component---src-pages-design-system-js": () => import("./../../../src/pages/design-system.js" /* webpackChunkName: "component---src-pages-design-system-js" */),
  "component---src-pages-development-index-js": () => import("./../../../src/pages/development/index.js" /* webpackChunkName: "component---src-pages-development-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-safely-js": () => import("./../../../src/pages/safely.js" /* webpackChunkName: "component---src-pages-safely-js" */)
}

